import React, { Component } from "react";
import "./vendor/bootstrap/css/bootstrap.min.css";
import "./fonts/iconic/css/material-design-iconic-font.min.css";
import "./fonts/font-awesome-4.7.0/css/font-awesome.min.css";
import "./vendor/css-hamburgers/hamburgers.min.css";
import "./vendor/animate/animate.css";
import "./vendor/select2/select2.min.css";
import "./vendor/animsition/css/animsition.min.css";
import "./vendor/daterangepicker/daterangepicker.css";
import "./css/util.css";
import "./css/main.css";
import main from "./js/main.js";
import worker from "./worker.js";
import WebWorker from "./workerSetup";
import cog from "./images/cogwheels.svg";
import StegIcon from "./images/stegicon.svg";
import GitHubButton from "react-github-btn";
// import you from "./images/youtube_social_icon_dark.png"
class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      esecret: "World is gonna end!!",
      epass: "",
      encrypt: true,
      hmac: false,
      emsg: "",
      rmsg: "",
      dpass: "",
      dmsg: "",
      dsecret: "",
      msgbox: "MESSAGE *",
      btn: "Encrypt",
      progress: false,
      err: "",
    };
  }
  componentDidMount() {
    this.worker = new WebWorker(worker);
    main();
  }
  hidemsg = (e) => {
    var { esecret, epass, emsg, hmac, encrypt } = this.state;
    if (epass == "" && encrypt) {
      let pass = document.getElementById("password");
      pass.setCustomValidity("Please add password, else uncheck encrypted");
      return;
    }
    if (
      !emsg.includes(" ") ||
      (emsg.includes(" ") && emsg.split(" ")[1].length == 0)
    ) {
      let msg = document.getElementById("message");
      msg.setCustomValidity("Cover message should contain at least 2 words!");
      return;
    }
    e.preventDefault();
    if (this.state.btn == "Encrypt") {
      let data = {};
      data["message"] = esecret;
      data["key"] = epass;
      if (!emsg.includes(" ")) {
        emsg = emsg + " ";
      }
      data["cover"] = emsg;
      this.worker.postMessage({ enc: true, data, hmac, encrypt });
      this.setState({ progress: true });
      this.worker.addEventListener("message", (e) => {
        if (e.data.enc) {
          let hidden = e.data.emsg;
          this.setState({ rmsg: hidden, btn: "Clear", progress: false });
        }
      });
    } else {
      this.setState({ btn: "Hide", rmsg: "", emsg: "" });
    }
  };
  demsg = (e) => {
    e.preventDefault();
    this.worker.postMessage({
      enc: false,
      dmsg: this.state.dmsg,
      pass: this.state.dpass,
    });
    this.worker.addEventListener("message", (e) => {
      if (!e.data.enc) {
        this.setState({ dsecret: e.data.pass });
      }
    });
  };
  copyClip = (e) => {
    e.preventDefault();
    let clip = document.getElementById("res");
    clip.select();
    clip.setSelectionRange(0, 99999);
    document.execCommand("copy");
  };
  docs = (cl) => {
    return document.querySelector(cl);
  };
  retRes = () => {
    return (
      <div style={{ width: "inherit" }}>
        <div className="flex-sb label-input100">
          <label className="" htmlFor="res">
            stegcloaked message*
          </label>
          <button
            style={{ width: "20px", height: "25px"}}
            onMouseEnter={() => (this.docs(".copy").style.opacity = 1)}
            onMouseLeave={() => (this.docs(".copy").style.opacity = 0)}
            id="clip"
            className="m-l-10 "
            color="white"
            onClick={(e) => this.copyClip(e)}
          >
            <svg
              aria-hidden="true"
              data-prefix="far"
              data-icon="copy"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 448 512"
            >
              <path stroke="#fff" d="M433.941 65.941l-51.882-51.882A48 48 0 0 0 348.118 0H176c-26.51 0-48 21.49-48 48v48H48c-26.51 0-48 21.49-48 48v320c0 26.51 21.49 48 48 48h224c26.51 0 48-21.49 48-48v-48h80c26.51 0 48-21.49 48-48V99.882a48 48 0 0 0-14.059-33.941zM266 464H54a6 6 0 0 1-6-6V150a6 6 0 0 1 6-6h74v224c0 26.51 21.49 48 48 48h96v42a6 6 0 0 1-6 6zm128-96H182a6 6 0 0 1-6-6V54a6 6 0 0 1 6-6h106v88c0 13.255 10.745 24 24 24h88v202a6 6 0 0 1-6 6zm6-256h-64V48h9.632c1.591 0 3.117.632 4.243 1.757l48.368 48.368a6 6 0 0 1 1.757 4.243V112z"></path>
            </svg>
          </button>
          <div className="copy">
            <span>Copy to Clipboard</span>
          </div>
        </div>
        <div style={{ marginBottom: "15px" }} className="wrap-input100 ">
          <textarea
            id="res"
            className="input100"
            name="message"
            value={this.state.rmsg}
            readOnly={true}
          ></textarea>
          <span className="focus-input100"></span>
        </div>
        <p className="d-input100">Copy and paste this anywhere.....</p>
      </div>
    );
  };
  retBtn = () => {
    return (
      <div className="container-contact100-form-btn m-b-25">
        <button
          className="contact100-form-btn validate-form"
          onClick={(e) => this.hidemsg(e)}
        >
          <span>
            {this.state.btn}
            <i className="zmdi zmdi-arrow-right m-l-8"></i>
          </span>
        </button>
      </div>
    );
  };

  render() {
    return (
      <div className="container-contact100">
        <div style={{ position: "relative" }} className="wrap-contact100">
          <form className="contact100-form validate-form">
            <p className=" p-input100 text-center m-t-35"> ENCRYPT </p>
            <hr className="hr-input100 m-t-2" />
              <label className=" label-input100 m-t-10 " htmlFor="first-name">
                MESSAGE TO ENCRYPT{" "}
              </label>
              <div className="wrap-input100 ">
              <textarea
                  id="first-name"
                  className="input100"
                  type="text"
                  name="first-name"
                  onChange={(e) => this.setState({ esecret: e.target.value })}
                />
                <span className="focus-input100"></span>
              </div>
            
              <label className=" label-input100 m-t-10 " htmlFor="password">
                ENCRYPTION KEY
              </label>
              <div className="wrap-input100  validate-input">
                <input
                  id="password"
                  className="input100 text-center"
                  type="password"
                  required="required"
                  disabled={!this.state.encrypt}
                  name="password"
                  onChange={(e) => this.setState({ epass: e.target.value })}
                />
                <span className="focus-input100"></span>
              </div>

            <div style={{ cursor: "pointer", textAlign: "left" }} id="adv1">
              <img
                className=" m-b-5"
                src={cog}
                alt="Extra"
                style={{ width: "20px" }}
              />
              <span
                style={{ fontSize: "18px", fontFamily: "Raleway-SemiBold" }}
                className=" m-t-15"
                id="adv"
              >
                {" "}
                Advanced{" "}
              </span>
            </div>
            <div className="flex-sa label-input100 cus" id="option">
              <div className=" m-t-15">
                <label
                  style={{ float: "left" }}
                  className="m-r-10"
                  htmlFor="myCheck"
                >
                  ENCRYPTED
                </label>
                <input
                  style={{ float: "left", width: "18px", height: "18px" }}
                  className="m-t-5"
                  type="checkbox"
                  defaultChecked="checked"
                  id="myCheck"
                  onClick={() =>
                    this.setState({ encrypt: !this.state.encrypt })
                  }
                />
              </div>
              <div className=" m-t-15">
                <label
                  style={{ float: "left" }}
                  className="m-r-10 "
                  htmlFor="myCheck1"
                >
                  HMAC
                </label>
                <input
                  style={{ float: "left", width: "18px", height: "18px" }}
                  className="m-t-5"
                  type="checkbox"
                  id="myCheck1"
                  onClick={() => this.setState({ hmac: !this.state.hmac })}
                />
              </div>
            </div>
            <label className=" label-input100 m-t-10 " htmlFor="message">
              PUBLIC MESSAGE
            </label>
            <div className="wrap-input100 validate-input">
              <input
                id="message"
                className="input100"
                name="message"
                onChange={(e) => this.setState({ emsg: e.target.value })}
                value={this.state.emsg}
              ></input>
              <span className="focus-input100"></span>
            </div>

            {this.retBtn()}
            {/* {(this.state.progress)?this.retProg():this.retBtn()} */}
            {this.state.btn === "Clear" ? this.retRes() : null}

            <p className=" p-input100 text-center m-t-35">DECRYPT </p>
            <hr className="hr-input100 m-t-2" />
            <div className="label-input100 flex-sa"></div>
            <label className="label-input100 m-l-2" htmlFor="pass">
              DECRYPTION KEY
            </label>

            <div className="wrap-input100 ">
              <input
                id="pass"
                className="input100"
                type="password"
                name="first-name"
                onChange={(e) => this.setState({ dpass: e.target.value })}
              />
              <span className="focus-input100"></span>
            </div>
            <label className=" label-input100 m-l-2" htmlFor="mess">
              Message to Decrypt
            </label>
            <div className="wrap-input100 ">
              <input
                id="mess"
                className="input100"
                name="mess"
                onChange={(e) => this.setState({ dmsg: e.target.value })}
              ></input>
              <span className="focus-input100"></span>
            </div>
            <div className="container-contact100-form-btn">
              <button
                className="contact100-form-btn"
                onClick={(e) => this.demsg(e)}
              >
                <span>
                  Decrypt
                  <i className="zmdi zmdi-arrow-right m-l-8"></i>
                </span>
              </button>
            </div>
            <div style={{ marginTop: "15px" }} className="wrap-input100 ">
          <textarea
            id="res"
            className="input100"
            name="message"
            rows="20"
            value={this.state.dsecret}
            readOnly={true}
          ></textarea>
          <span className="focus-input100"></span>
        </div>
          </form>
        </div>
      </div>
    );
  }
}

export default App;
